import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { StandingChargeFragment } from '@/services/typed-graphql-sdk';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';

/**
 * Display time of use Standing charges for All Electric tariff
 * Product time of use Standing charge details differer by grid operator area
 */
export const StandingChargesAllElectric: FC<{
	gridOperatorArea?: string;
	standingChargePerDay?: string | null;
	standingCharges?: StandingChargeFragment[];
	variant?: 'onboarding' | 'mypage' | 'tariffs';
}> = ({
	gridOperatorArea,
	standingCharges = [],
	standingChargePerDay,
	variant = 'mypage',
}) => {
	const { t } = useTranslation();
	const hasMultipleStandingCharges = standingCharges.length > 1;

	if (standingCharges.length === 0 && !standingChargePerDay) {
		return null;
	}

	const [firstStandingCharge, secondStandingCharge] = standingCharges;

	return (
		<div className="w-full text-base">
			<h3
				className={clsx('mb-3 font-bold', {
					'text-xl': variant === 'tariffs',
				})}
			>
				{t('obj:product.basic-charge')}{' '}
				{gridOperatorArea && `(${gridOperatorArea}エリア)`}:
			</h3>
			<div
				className={clsx({
					'space-y-0': variant === 'mypage',
					'space-y-1 text-xs': variant === 'onboarding',
				})}
			>
				{standingChargePerDay ? (
					<p>
						1日あたり {currencyFormatter.format(Number(standingChargePerDay))}円
					</p>
				) : firstStandingCharge ? (
					<p>
						{hasMultipleStandingCharges ? '10kWまで ' : ''}1日あたり&nbsp;
						{currencyFormatter.format(
							Number(firstStandingCharge.pricePerUnitIncTax)
						)}
						円/kW
					</p>
				) : null}
				{secondStandingCharge && (
					<p>
						10kWをこえる1kW につき&nbsp;1日あたり&nbsp;
						{currencyFormatter.format(
							Number(secondStandingCharge.pricePerUnitIncTax)
						)}
						円
					</p>
				)}
			</div>
		</div>
	);
};
