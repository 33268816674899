import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
	EVTimeOfUseConsumptionCharges,
	SolarOctopusTimeOfUseConsumptionCharges,
} from '@/components/shared/ConsumptionCharges';
import { ConsumptionChargesAllElectric } from '@/components/shared/ConsumptionChargesAllElectric';
import { StandingChargesAllElectric } from '@/components/shared/StandingChargesAllElectric';
import { copy } from '@/copy';
import {
	StandingChargeFragment,
	SteppedConsumptionRate,
} from '@/services/typed-graphql-sdk';
import {
	GRID_AREA_NAME_BY_GRID_OPERATOR_CODE,
	GridOperatorCode,
} from '@/utils/constants/industry/gridOperator';
import {
	STANDING_CHARGE_UNIT,
	StandingChargeUnitType,
} from '@/utils/constants/industry/industry';
import { padNumberWithTrailingZeroesToTwoDecimals } from '@/utils/formatters/padNumberWithTrailingZeroesToTwoDecimals';

export const ProductPriceMyPage: FC<{
	amperage?: Maybe<number>;
	consumptionCharges: SteppedConsumptionRate[];
	gridOperatorCode?: GridOperatorCode;
	isAllElectricProduct?: boolean;
	isElectricVehicleProduct?: boolean;
	isPriceTentative?: boolean;
	isSimpleOctopusProduct?: boolean;
	isSolarOctopusProduct?: boolean;
	kva?: Maybe<number>;
	standingChargePerDay?: string | null;
	standingChargeUnitType: StandingChargeUnitType;
	standingCharges: StandingChargeFragment[];
}> = ({
	standingChargePerDay,
	standingChargeUnitType,
	consumptionCharges,
	kva,
	amperage,
	gridOperatorCode,
	isAllElectricProduct,
	isElectricVehicleProduct,
	isSimpleOctopusProduct,
	isSolarOctopusProduct,
	standingCharges,
}) => {
	const { t } = useTranslation();
	return (
		<div className="space-y-4 text-ice">
			{/* Standing Charges Section */}
			{isAllElectricProduct ? (
				<StandingChargesAllElectric
					standingChargePerDay={standingChargePerDay}
					standingCharges={standingCharges}
				/>
			) : isElectricVehicleProduct || isSolarOctopusProduct ? (
				<TimeOfUseStandingChargesMyPage
					standingCharges={standingCharges}
					standingChargeUnitType={standingChargeUnitType}
				/>
			) : isSimpleOctopusProduct ? (
				<p className="pb-2">
					<Trans
						i18nKey="account:property-section.no-basic-charge-no-fuel-adjustment"
						components={{
							br: <br />,
						}}
					/>
				</p>
			) : (
				<>
					<p>
						{t('account:property-section.basic-charge')}
						{gridOperatorCode && (
							<>
								&nbsp;(
								{`${GRID_AREA_NAME_BY_GRID_OPERATOR_CODE[gridOperatorCode]}電力エリア`}
								)
							</>
						)}
					</p>
					<div className="font-bold">
						{standingChargePerDay}
						円/
						{standingChargeUnitType !== 'YEN_DAY' && (kva || amperage || '')}
						{STANDING_CHARGE_UNIT[standingChargeUnitType]}
					</div>
				</>
			)}
			{/* Consumption Charges Section */}
			<div className={isSimpleOctopusProduct ? 'flex' : 'flex flex-col'}>
				<p className="mb-3 mr-1 text-base font-bold">
					{t('account:property-section.consumption-charge')}
					{isSimpleOctopusProduct ? '・' : ':'}
				</p>
				{isAllElectricProduct && gridOperatorCode ? (
					<ConsumptionChargesAllElectric
						consumptionCharges={consumptionCharges}
						gridOperatorCode={gridOperatorCode}
					/>
				) : isElectricVehicleProduct ? (
					<EVTimeOfUseConsumptionCharges
						consumptionCharges={consumptionCharges}
					/>
				) : isSolarOctopusProduct ? (
					<SolarOctopusTimeOfUseConsumptionCharges
						consumptionCharges={consumptionCharges}
					/>
				) : (
					<ConsumptionChargesMyPage consumptionCharges={consumptionCharges} />
				)}
			</div>
		</div>
	);
};

const ConsumptionChargesMyPage: FC<{
	consumptionCharges: SteppedConsumptionRate[];
}> = ({ consumptionCharges }) => (
	<div className="flex max-w-sm flex-wrap justify-between">
		{consumptionCharges.map((charge, index) => (
			<div
				className="flex flex-col"
				key={(charge.pricePerUnitIncTax + index).toString()}
			>
				<div className="whitespace-nowrap text-base font-bold">
					{charge.pricePerUnitIncTax}円/kWh
				</div>
				{charge.stepStart || charge.stepEnd ? (
					<div className="whitespace-nowrap text-xs text-pollution">
						{consumptionChargeStepLabel(charge)}
					</div>
				) : null}
			</div>
		))}
	</div>
);

const TimeOfUseStandingChargesMyPage: FC<{
	standingChargeUnitType: StandingChargeUnitType;
	standingCharges: StandingChargeFragment[];
}> = ({ standingCharges, standingChargeUnitType }) => {
	const [firstStandingCharge] = standingCharges;

	/*
	 * When the standing charge unit type is YEN_DAY, the price is a flat rate that does not need to be multiplied.
	 * */
	const price =
		standingChargeUnitType === 'YEN_DAY'
			? padNumberWithTrailingZeroesToTwoDecimals(
					Number(firstStandingCharge.pricePerUnitIncTax)
				)
			: padNumberWithTrailingZeroesToTwoDecimals(
					Number(firstStandingCharge.pricePerUnitIncTax) * 10
				);
	return (
		<div className="flex flex-col gap-3 text-base">
			<p className="font-bold">{copy.basicCharge}:</p>
			{standingChargeUnitType === 'YEN_DAY' ? (
				<p className="font-medium">
					{price}
					円/日
				</p>
			) : standingChargeUnitType === 'YEN_KVA_DAY' ? (
				<p className="font-medium">
					{price}
					円/kVA/日
				</p>
			) : standingChargeUnitType === 'YEN_AMPERE_DAY' ? (
				<p className="font-medium">
					{price}
					円/10A/日
				</p>
			) : (
				<p className="font-medium">
					{price}
					円/kVA/日
				</p>
			)}
		</div>
	);
};

const consumptionChargeStepLabel = (
	charge: Pick<SteppedConsumptionRate, 'stepEnd' | 'stepStart'>
): string => `${
	charge.stepStart && charge.stepStart > 0
		? charge.stepStart + 1
		: charge.stepStart
}
${charge.stepEnd ? ` ~ ${charge.stepEnd}kWh` : 'kWh ~'}`;
